import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'
import Search, { SearchSelect, SearchText } from '#Search'
import { DisplayDate, DisplayUser, useEvalUser } from '#Display'
import typesDef from './types.json'

import { faBomb, faEdit, faFire, faPlus, faRecycle, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { Typography } from '@mui/material';

const TimeMachineField = ({ row }) => (
	<>
		{ row._revised && <Typography variant="overline" display="block">Revisionato</Typography> }
		{ row._deleted && <Typography variant="overline" display="block">Eliminato</Typography> }
		<Typography variant="caption" display="block">revisione #{row._revision || 0}</Typography>
		<Typography variant="caption" display="block">
			Creato: <DisplayDate value={row._created} />
		</Typography>
		<Typography variant="caption" display="block">
			<DisplayUser uid={row._createdBy} />
		</Typography>
		{ row._created!==row._edited && (
			<>
				<Typography variant="caption" display="block">
					Modificato: <DisplayDate value={row._edited} />
				</Typography>
				<Typography variant="caption" display="block">
					<DisplayUser uid={row._editedBy} />
				</Typography>
			</>
		)}
	</>
)

const useTableDef = assetType => {
	const api = useContext(APICtx)
	return useMemo(() => {
		const assetDef = typesDef.find(item => item.value===assetType)
		const def = [
			{ label:"Bene", field:'number' },
		]
		api.auth.chk('assets-timemachine') && 
			def.push({ label:"Ultima mod.", content: row => <TimeMachineField row={row} /> })
		assetDef?.fields.includes('shortDescription') &&
			def.push({ label:"Descr. ridotta", field:'shortDescription' })
		assetDef?.fields.includes('equipments') &&
			def.push({ label:"Attrezzatura", field:'equipments', type:'multiline' })
		assetDef?.fields.includes('supplier') &&
			def.push({ label:"Fornitore", field:'supplier' })
		assetDef?.fields.includes('date') &&
			def.push({ label:"Data", field:'date', type:'date' })
		assetDef?.fields.includes('previousCustomer') &&
			def.push({ label:"Ex cliente", field:'previousCustomer' })
		assetDef?.fields.includes('customer') &&
			def.push({ label:"Cliente", field:'customer' })
		assetDef?.fields.includes('agent') &&
			def.push({ label:"Agente", field:'agent' })
		assetDef?.fields.includes('price') &&
			def.push({ label:"Prezzo", field:'price', type:'money' })
		assetDef?.fields.includes('rebate') &&
			def.push({ label:"Sconto", content:row => row.rebate ? (row.rebate/10000).toFixed(2) + ' %' : '-' })
		assetDef?.fields.includes('rebateBattery') &&
			def.push({ label:"Sconto batt.", content:row => row.rebateBattery ? (row.rebateBattery/10000).toFixed(2) + ' %' : '-' })
		assetDef?.fields.includes('protocol') &&
			def.push({ label:"Protocollo", field:'protocol' })
		assetDef?.fields.includes('model') &&
			def.push({ label:"Modello", field:'model' })
		assetDef?.fields.includes('serial') &&
			def.push({ label:"Matricola", field:'serial' })
		assetDef?.fields.includes('assetRef') &&
			def.push({ label:"Rif. bene", field:'assetRef' })
		assetDef?.fields.includes('ddtNumber') &&
			def.push({ label:"Num. DDT", field:'ddtNumber' })
		assetDef?.fields.includes('ddtDate') &&
			def.push({ label:"Data DDT", field:'ddtDate', type:'date' })
		assetDef?.fields.includes('sellPrice') &&
			def.push({ label:"Importo vendita", field:'sellPrice', type:'money' })
		assetDef?.fields.includes('finalCustomer') &&
			def.push({ label:"Cliente finale", field:'finalCustomer' })
		def.push({ label:"Note", field:'notes', type:'multiline' })
		return def
	}, [ assetType ])
}

const useCsvDef = assetType => {
	const api = useContext(APICtx)
	const evalUser = useEvalUser()
	return useMemo(() => {
		const assetDef = typesDef.find(item => item.value===assetType)
		const def = [
			{ label:"Bene", field:'number' },
		]
		api.auth.chk('assets-timemachine') && def.push(
			{ label:"Rev. numero", content:row => row._revision },
			{ label:"Stato", content:row => row._deleted ? "eliminato" : (row._revised ? "revisionato" : "attivo") },
			{ label:"Creato il", type:'date', content:row => row._created },
			{ label:"Creato da", content:row => evalUser(row._createdBy) },
			{ label:"Modificato il", type:'date', content:row => row._edited },
			{ label:"Modificato da", content:row => evalUser(row._editedBy) },
		)
		assetDef?.fields.includes('shortDescription') &&
			def.push({ label:"Descr. ridotta", field:'shortDescription' })
		assetDef?.fields.includes('equipments') &&
			def.push({ label:"Attrezzatura", content:row => row?.equipments })
		assetDef?.fields.includes('supplier') &&
			def.push({ label:"Fornitore", field:'supplier' })
		assetDef?.fields.includes('date') &&
			def.push({ label:"Data", field:'date', type:'date' })
		assetDef?.fields.includes('previousCustomer') &&
			def.push({ label:"Ex cliente", field:'previousCustomer' })
		assetDef?.fields.includes('customer') &&
			def.push({ label:"Cliente", field:'customer' })
		assetDef?.fields.includes('agent') &&
			def.push({ label:"Agente", field:'agent' })
		assetDef?.fields.includes('price') &&
			def.push({ label:"Prezzo", content:row => row.price/10000 })
		assetDef?.fields.includes('rebate') &&
			def.push({ label:"Sconto", content:row => row.rebate ? (row.rebate/10000).toFixed(2) + ' %' : '-' })
		assetDef?.fields.includes('rebateBattery') &&
			def.push({ label:"Sconto batt.", content:row => row.rebateBattery ? (row.rebateBattery/10000).toFixed(2) + ' %' : '-' })
		assetDef?.fields.includes('protocol') &&
			def.push({ label:"Protocollo", field:'protocol' })
		assetDef?.fields.includes('model') &&
			def.push({ label:"Modello", field:'model' })
		assetDef?.fields.includes('serial') &&
			def.push({ label:"Matricola", field:'serial' })
		assetDef?.fields.includes('assetRef') &&
			def.push({ label:"Rif. bene", field:'assetRef' })
		assetDef?.fields.includes('ddtNumber') &&
			def.push({ label:"Num. DDT", field:'ddtNumber' })
		assetDef?.fields.includes('ddtDate') &&
			def.push({ label:"Data DDT", field:'ddtDate', type:'date' })
		assetDef?.fields.includes('sellPrice') &&
			def.push({ label:"Importo vendita", content:row => row.sellPrice/10000 })
		assetDef?.fields.includes('finalCustomer') &&
			def.push({ label:"Cliente finale", field:'finalCustomer' })
		def.push({ label:"Note", content:row => row?.notes })
		return def
	}, [ assetType ])
}

const tmOptions = [
	{ value:'', label:'Solo gli attivi' },
	{ value:'all', label:'Anche revisionati ed eliminati' },
]

export default function TrucksList() {
	const api = useContext(APICtx)
	const { assetType } = useParams()
	const [ ids, setIds ] = useState([])
	const [ srcString, setSrcString ] = useState('')
	const [ srcTM, setSrcTM ] = useState('')

	const tabDef = useTableDef(assetType)
	const csvDef = useCsvDef(assetType)
	const assetDef = typesDef.find(item => item.value===assetType)

	const handleDelete = ({ _id, number, handleRowRefresh }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il bene "'+number+'"?',
			onConfirm: () => api.call('assets/del/'+_id).then(async () => {
				await handleRowRefresh()
				await handleRefresh()
			}),
		})
	}
	const handleUndelete = ({ _id, number, handleRowRefresh }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler riattivare il bene "'+number+'"?',
			onConfirm: () => api.call('assets/undel/'+_id).then(handleRowRefresh),
		})
	}
	// const handleDestroy = ({ _id, number }) => {
	// 	api.dialogConfirm({
	// 		text: 'Sei sicuro di voler eliminare definitivamente tutti i dati sul bene "'+number+'"?',
	// 		onConfirm: () => api.call('assets/destroy/'+_id).then(handleRefresh),
	// 	})
	// }
	const handleRefresh = () => api.call('assets/list', { type:assetType, srcString, srcTM }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ assetType, srcString, srcTM ])

	const getRow = _id => api.call('assets/get/'+_id)
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => "/assets/"+assetType+"/edit/"+row._id,
			show: row => !row._revised && !row._deleted,
		},
	]
	if(api.auth.chk('assets-timemachine'))
		defBtns.push(
			{
				icon: faTrash,
				label: 'Elimina',
				onClick: handleDelete,
				show: row => !row._revised && !row._deleted,
			},
			{
				icon: faRecycle,
				label: 'Recupera',
				onClick: handleUndelete,
				show: row => !row._revised && row._deleted,
			},
		)
		// {
		// 	icon: faFire,
		// 	label: 'Elimina definitivamente',
		// 	onClick: handleDestroy,
		// 	show: row => !row._revised && row._deleted,
		// },

	const csvName = assetDef.labelMany?.toLowerCase()
	return (
		<Tpl title={'Registro '+(assetDef.labelMany || '???')}>
			<Search>
				<SearchText label="Cerca" name="src-text" onChange={setSrcString} />
				{ api.auth.chk('assets-timemachine') && <SearchSelect name="src-tm" onChange={setSrcTM} label="Time machine" options={tmOptions} /> }
			</Search>
			{/* <Table def={tabDef} csvDef={csvDef} csvName={csvName} defBtns={defBtns} ids={ids} getRow={getRow} pageSizeOpts={[ 30, 50, 100 ]} pageSize={30} /> */}
			<DataList def={tabDef} csvDef={csvDef} csvName={csvName} rowActions={defBtns} ids={ids} getRow={getRow} pageSizeOpts={[ 30, 50, 100 ]} pageSize={30} />
			<Fab label="Aggiungi bene" icon={faPlus} linkTo={"/assets/"+assetType+"/edit"} />
		</Tpl>
	)
}