import React, { useState, useEffect, useContext } from 'react';

import APICtx from '#api'
import Form, { Select } from '#Form'

import offerStatus from './offerStatus.json'

export default function OffersStatusEdit({ _id }) {
	const api = useContext(APICtx)
	const [ status, setStatus ] = useState('')
	const [ lostOffersReasonsOpts, setLostOffersReasonsOpts ] = useState([])
	
	useEffect(() => {
		api.call('lost-offer-reasons/options').then(setLostOffersReasonsOpts)
	}, [])

	const formDef = {
		_id,
		callSet: "offers/set-status",
		callGet: _id && "offers/get-status",
		onLoad: data => {
			setStatus(data.tag)
		},
	}

	return (
		<Form {...formDef}>
			<Select
				label="Stato offerta"
				name="tag"
				required
				options={offerStatus}
				onChange={setStatus}
				emptyLabel="--- seleziona uno stato ---"
			/>
			<Select
				label="Ragione offerta persa"
				name="lostOfferReason"
				required
				options={lostOffersReasonsOpts}
				disabled={!offerStatus.find(item => item.value===status)?.lost}
				emptyLabel="--- seleziona una tipologia ---"
			/>
		</Form>
	)
}
