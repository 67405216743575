import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputNumber, Select, Autocomplete } from '#Form'
import offerTypes from './offerTypes.json'
// import rentPeriodTypes from './rentPeriodTypes.json'
import { Paper } from '@mui/material';

export default function OffersEdit() {
	const api = useContext(APICtx)
	const { _id } = useParams()
	const [ tradersOptions, setTraderOptions ] = useState([])
	const [ categoriesOpts, setCategoriesOpts ] = useState([])
	const [ productsOpts, setProductsOpts ] = useState([])
	const [ workingHeight, setWorkingHeight ] = useState(0)
	const [ type, setType ] = useState(null)
	const [ category, setCategory ] = useState(null)
	const [ product, setProduct ] = useState(null)
	const [ productData, setProductData ] = useState({})
	const [ transportPrices, setTransportPrices ] = useState([])
	const [ email1, setEmail1 ] = useState(null)
	const [ email2, setEmail2 ] = useState(null)
	const [ email3, setEmail3 ] = useState(null)
	const [ email4, setEmail4 ] = useState(null)
	const [ email5, setEmail5 ] = useState(null)
	const [ data, setData ] = useState({})

	const autocompleteEmail = async (hint) => {
		const emails = await api.call('offers/listEmails', { hint })
		if(!emails.includes(hint))
			emails.unshift(hint)
		return emails.map(email =>
			({ value:email, label:email })
		)
	}

	useEffect(() => {
		product ? api.call('platforms/get', { _id:product }).then(setProductData) : setProductData({})
	}, [ product ])
	useEffect(() => {
		productData?.notes && setData({ ...data, notes:productData.notes })
	}, [ productData ])
	useEffect(() => {
		api.call('products-categories/options', { type:'platform', workingHeight }).then(setCategoriesOpts)
	}, [ workingHeight ])
	useEffect(() => {
		category ? api.call('platforms/options', { category, workingHeight }).then(setProductsOpts) : setProductsOpts([])
	}, [ workingHeight, category ])
	useEffect(() => {
		api.auth.chk('offers-dataentry') && api.auth.usersAsOptions({ acl:'offers-trader' }).then(setTraderOptions)
		api.call('transport-prices/options', { mode:'socar' }).then(setTransportPrices)
	}, [])

	const title = (_id ? 'Modifica' : 'Crea') + ' offerta'
	const formDef = {
		_id,
		callSet: "offers/set",
		callGet: _id && "offers/get",
		data, setData,
		onLoad: data => {
			setType(data.type)
			setCategory(data.productCategory)
			setProduct(data.product)
			setEmail1(data.emailRecipient1)
			setEmail2(data.emailRecipient2)
			setEmail3(data.emailRecipient3)
			setEmail4(data.emailRecipient4)
			setEmail5(data.emailRecipient5)
		},
	}

	const emailsCount = [
		email1,
		email2,
		email3,
		email4,
		email5,
	].filter(Boolean).length
	return (
		<Tpl title={title} backTo="/platforms/offers">
			<Form {...formDef}>
				<Paper sx={{ p:1, mb:1 }}>
					{ api.auth.chk('offers-dataentry') && (
						<Select label="Commerciale" name="trader" options={tradersOptions} required emptyLabel="-- Seleziona un commerciale --" />
					)}
					<Autocomplete label="Email" name="emailRecipient1" getOptions={autocompleteEmail} required onChange={setEmail1} />
					{ Boolean(emailsCount>0 || email2) && <Autocomplete label="Email" name="emailRecipient2" getOptions={autocompleteEmail} onChange={setEmail2} /> }
					{ Boolean(emailsCount>1 || email3) && <Autocomplete label="Email" name="emailRecipient3" getOptions={autocompleteEmail} onChange={setEmail3} /> }
					{ Boolean(emailsCount>2 || email4) && <Autocomplete label="Email" name="emailRecipient4" getOptions={autocompleteEmail} onChange={setEmail4} /> }
					{ Boolean(emailsCount>3 || email5) && <Autocomplete label="Email" name="emailRecipient5" getOptions={autocompleteEmail} onChange={setEmail5} /> }
					<Input label="Ragione sociale" name="offerRecipient" required />
					<Select label="Tipo di offerta" name="type" required options={offerTypes} onChange={setType} emptyLabel="--- seleziona una tipologia ---" />
				</Paper>
				<Paper sx={{ p:1, mb:1 }}>
					<InputNumber label="Filtra per altezza di lavoro" name="filterWorkingHeight" suffix="m" onChange={setWorkingHeight} />
					<Select label="Categoria prodotti" name="productCategory" required options={categoriesOpts} onChange={setCategory} emptyLabel="--- seleziona una categoria ---" />
					<Select label="Prodotto" name="product" required options={productsOpts} onChange={setProduct} emptyLabel="--- seleziona un prodotto ---" />
					{ type==='sale-used' && <Input label="Riferimento interno del prodotto" name="productReference" />}
				</Paper>
				
				<Paper sx={{ p:1, mb:1 }}>
					<InputNumber label="Prezzo" name="price" suffix="€" required />
					{ Boolean(productData?.transportRequired) && (
						<>
							<Select label="Costi di trasporto" name="transportCosts" options={transportPrices} emptyLabel="Da definire" />
							<Input label="Destinazione trasporto" name="transportDestination" />
						</>
					)}
					<Input label="Note" name="notes" multiline />
				</Paper>
			</Form>
		</Tpl>
	)
}
