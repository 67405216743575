// import types from './transportTypes.json'
// import warehouses from './warehouses.json'

export const displayDows = [ 1, 2, 3, 4, 5 ]
export const dows = [ 'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato' ]
export const months = [ 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre' ]

export const types = [
	{ "value":"delivery", "label":"Consegna" },
	{ "value":"collection", "label":"Ritiro" },
	{ "value":"transfer", "label":"Trasferimento" }
]

export const priorities = [
	{ value:'day', label:'In giornata' },
	{ value:'a', label:'Prima consegna' },
	{ value:'b', label:'Seconda consegna' },
]
export const prioritiesDisplay = [
	{ value:'day', label:'In giornata' },
	{ value:'a', label:'Prima consegna' },
	{ value:'b', label:'Seconda consegna' },
	{ value:'taken', label:'Auto-assegnato' },
]

export const warehouses = [
	{ "value":"socar-bs", "label":"Socar, sede BS" },
	{ "value":"socar-cr", "label":"Socar, sede CR" }
]

export const evalAddrFrom = item =>
	item?.type === 'delivery' ? (warehouses.find(warehouse => warehouse.value === item?.addressFrom)?.label || '?') : item?.addressFrom

export const evalAddrTo = item =>
	item?.type==='collection' ? (warehouses.find(warehouse => warehouse.value === item?.addressTo)?.label || '?') : item?.addressTo

export const evalType = item =>
	types.find(typeCmp => typeCmp.value === item?.type)?.label || '?'

export const evalPriority = item =>
	prioritiesDisplay.find(priorityCmp => priorityCmp.value === item?.priority)?.label || '?'