import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputNumber, Checkbox, Select, FileUpload } from '#Form'

export default function DatasheetEdit() {
	const api = useContext(APICtx)
	const { _id } = useParams()
	const [ categories, setCategories ] = useState([])

	useEffect(() => {
		api.call('products-categories/options', { type:'platform' }).then(setCategories)
	}, [])

	const title = (_id ? 'Modifica' : 'Crea') + ' piattaforma'
	const formDef = {
		_id,
		callSet: "platforms/set",
		callGet: _id && "platforms/get",
	}
	return (
		<Tpl title={title} backTo="/platforms">
			<Form {...formDef}>
				<Select label="Categoria" name="category" required options={categories} emptyLabel="--- seleziona una categoria ---" />
				<Input label="Nome" name="name" required />
				<InputNumber label="Altezza di lavoro" name="workingHeight" suffix="m" required />
				<InputNumber label="Sbraccio" name="outreach" suffix="m" />
				<Checkbox label="Richiede il trasporto?" name="transportRequired" />
				<InputNumber label="Canone giornaliero" name="priceRentDaily" suffix="€" />
				<InputNumber label="Canone mensile" name="priceRentMonthly" suffix="€" />
				<FileUpload label="Scheda tecnica" name="datasheet" tokenEndpoint="platforms/get-upload-token" />
				<Input label="Note" name="notes" multiline />
			</Form>
		</Tpl>
	)
}
