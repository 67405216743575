import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams, useNavigate } from "react-router-dom"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faChevronLeft, faChevronRight, faRabbitFast, faDog, faTurtle, faTruck, faTruckTow } from '@fortawesome/pro-solid-svg-icons'
import { faCalendar, faHandHoldingBox, faTruckClock, faLock, faUnlock, faClipboardList } from '@fortawesome/pro-regular-svg-icons'
import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import { Typography } from '@mui/material';
import Button from '#Button'
// import Form, { Input, InputDate, Select } from '#Form'
import { dows, months, displayDows } from './const'

const calcToday = () => {
	const date = new Date()
	return date.getFullYear() + '-' + (date.getMonth()+1)
}
const calcParams = monthYear => {
	const [ year, month ] = monthYear.split('-').map(i => parseInt(i))
	const monthDays = new Date(year, month, 0).getDate()
	const firstDOW = new Date(year, month-1, 1).getDay()
	const weeksQty = Math.ceil((monthDays + (firstDOW || 7) -1) / 7)
	const nextDate = new Date(year, month, 1)
	const prevDate = new Date(year, month-2, 1)

	return {
		year,
		month,
		monthDays,
		firstDOW,
		weeksQty,
		prevDate: prevDate.getFullYear() + '-' + (prevDate.getMonth()+1),
		nextDate: nextDate.getFullYear() + '-' + (nextDate.getMonth()+1),
	}
}

const calcDay = p => {
	const day = (7*p.curWeek) + ( p.dowNum || 7 ) - (p.firstDOW || 7) + 1
	return (day<=0 || day>p.monthDays) ? null : day
}

const TabHead = ({ month, year, prevDate, nextDate }) => {
	return (
		<TableHead>
			<TableRow>
				<TableCell colSpan={dows.length}>
					<Box sx={{ display:'flex', justifyContent:'space-between' }}>
						<Box sx={{ mt:'auto', mb:'auto' }}>
							<Button icon={faChevronLeft} linkTo={"/transports/cal/"+prevDate} />
						</Box>
						<Box>
							<Typography variant='h4'>
								<Button icon={faCalendar} linkTo="/transports/cal" />
								{months[month-1] + ' ' + year}
							</Typography>
						</Box>
						<Box sx={{ mt:'auto', mb:'auto' }}>
							<Button icon={faChevronRight} linkTo={"/transports/cal/"+nextDate} />
						</Box>
					</Box>
				</TableCell>
			</TableRow>
			<TableRow>
				{ displayDows.map(dowNum => (
					<TableCell key={dowNum}>{dows[dowNum]}</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

const TabBody = p => {
	const rows = []
	const firstDisplayWeek = (p.firstDOW < 1 || p.firstDOW > 5) ? 1 : 0
	for(let curWeek=firstDisplayWeek; curWeek<p.weeksQty; curWeek++)
		rows.push(<TabRow key={curWeek} curWeek={curWeek} {...p} />)
	return (
		<TableBody>
			{rows}
		</TableBody>
	)
}

const TabRow = p => (
	<TableRow>
		{ displayDows.map(dowNum => (
			<TabCell key={dowNum} dayNum={calcDay({ ...p, dowNum })} dowNum={dowNum} {...p} />
		))}
	</TableRow>
)

const TabCell = ({ dayNum, year, month }) => {
	const api = useContext(APICtx)
	const [ dayInfo, setDayInfo ] = useState({})
	const date = useMemo(() => dayNum ? year + '-' + (month.toString().padStart(2, '0')) + '-' + (dayNum.toString().padStart(2, '0')) : null, [ dayNum ])

	const handleRefresh = () => {
		dayNum && api.call('transports/date/' + date).then(setDayInfo)
	}
	useEffect(() => {
		handleRefresh()
	}, [ date ])

	if(!dayNum)
		return <TableCell></TableCell>
	return (
		<TableCell sx={{ p:1, border:'1px solid rgba(224, 224, 224, 1)' }}>
			<Box sx={{ display:'flex', justifyContent:'space-between' }}>
				<DayCounters {...dayInfo} />
				<Box>
					<Typography variant='h3'>
						{ dayInfo.locked && <FontAwesomeIcon icon={faLock} size="xs" /> } {dayNum}
					</Typography>
				</Box>
			</Box>
			<DayTrucks {...dayInfo} date={date} handleRefresh={handleRefresh} />
			<DayActions {...dayInfo} date={date} handleRefresh={handleRefresh} />
		</TableCell>
	)
}

const DayCounters = ({ transports, trucks }) => {
	const trMax = trucks?.filter(truck => truck.available && !truck.isSmall).length || 0
	const trA = transports?.a || 0
	const trB = transports?.b || 0
	const trDay = transports?.day || 0
	return (
		<Box>
			<Box color={trA < trMax ? 'green' : 'red'}>
				<Typography variant="h5">
					{trA} <FontAwesomeIcon icon={faRabbitFast} />
				</Typography>
			</Box>
			<Box color={trB < trMax ? 'green' : 'red'}>
				<Typography variant="h5">
					{trB} <FontAwesomeIcon icon={faDog} />
				</Typography>
			</Box>
			<Box>
				<Typography variant="h5">
					{trDay} <FontAwesomeIcon icon={faTurtle} />
				</Typography>
			</Box>
		</Box>
	)
}

const DayTrucks = ({ trucks, date, handleRefresh }) => (
	<Box sx={{ mt:2 }}>
		{ Array.isArray(trucks) && trucks.map(truck => (
			<Box key={truck._id} display="inline">
				<DayTruck {...truck} date={date} handleRefresh={handleRefresh} />
			</Box>
		))}
	</Box>
)

const DayTruck = ({ date, handleRefresh, _id, available, name, letter, isSmall }) => {
	const api = useContext(APICtx)
	
	const handleToggle = () =>
		api.call('transports/truck/' + _id + '/' + date + '/' + (available ? 'off' : 'on'))
			.then(handleRefresh)

	return (
		<Button
			color={available ? 'success' : 'error'}
			tooltip={name}
			icon={isSmall ? faTruckTow : faTruck}
			iconSize="xs"
			badgeContent={letter}
			onClick={handleToggle}
		/>
	)
}

const DayActions = ({ date, handleRefresh, locked, ...info }) => {
	const api = useContext(APICtx)

	const handleLock = () => api.call('transports/lock/'+date).then(handleRefresh)
	const handleUnlock = () => api.call('transports/unlock/'+date).then(handleRefresh)

	return (
		<Box display="flex" justifyContent="right" sx={{ m:0 }}>
			{ locked && api.auth.chk('transport-driver') && (
				<Button iconSize="sm" icon={faHandHoldingBox} tooltip="Riepilogo trasporti" linkTo={'/transports/trucks/tasks/'+date} />
			)}
			
			{ locked && api.auth.chk('transport-manager') && (
				<Button iconSize="sm" icon={faClipboardList} tooltip="Organizza giornata" linkTo={'/transports/planner/'+date} />
			)}
			
			{ api.auth.chk('transport-user') && (
				<Button iconSize="sm" icon={faTruckClock} tooltip="Gestione trasporti" linkTo={'/transports/schedule/'+date} />
			)}
			
			{ !locked && api.auth.chk('transport-manager') && (
				<Button iconSize="sm" icon={faUnlock} tooltip="Blocca giornata" onClick={handleLock} />
			)}
			
			{ locked && api.auth.chk('transport-manager') && (
				<Button iconSize="sm" icon={faLock} tooltip="Sblocca giornata" onClick={handleUnlock} />
			)}
		</Box>
	)
}

export default function Cal() {
	const navigate = useNavigate()
	const { date:curDate } = useParams()
	const [ dateParams, setDateParams ] = useState(null)

	useEffect(() => {
		if(curDate)
			setDateParams(calcParams(curDate))
		else
			navigate('/transports/cal/'+calcToday(), { replace:true })
	}, [ curDate ])

	return (
		<Tpl title="Calendario trasporti">
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TabHead {...dateParams} />
					{ dateParams && <TabBody {...dateParams} /> }
				</Table>
			</TableContainer>
		</Tpl>
	)
}