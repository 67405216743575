import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputDate, Select, Checkbox } from '#Form'

export default function TrucksEdit() {
	const api = useContext(APICtx)
	const { _id } = useParams()
	const [ truckUserOpts, setTruckUserOpts ] = useState([])

	useEffect(() => {
		api.auth.usersAsOptions().then(setTruckUserOpts)
	}, [])

	const title = (_id ? 'Modifica' : 'Crea') + ' autocarro'
	const formDef = {
		callSet: [ "transports-trucks/set", _id ],
		callGet: _id && "transports-trucks/get/"+_id,
		idRedirect: _id => '/transports/trucks/edit/'+_id,
	}
	return (
		<Tpl title={title} backTo="/transports/trucks">
			<Form {...formDef}>
				<Input label="Nome" name="name" required />
				<Input label="Lettera identificativa" name="letter" required />
				<Checkbox label="Furgone di dimensioni ridotte" name="isSmall" />
				<Select label="Utente" name="user" options={truckUserOpts} emptyLabel="-- Seleziona un guidatore --" />
				<InputDate label="Attivo dal" name="begin" />
				<InputDate label="Attivo al" name="end" />
			</Form>
		</Tpl>
	)
}
