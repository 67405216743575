import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"

import { useQuery } from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Select } from '#Form'
import { types, warehouses } from './const'

const defaultWarehouse = warehouses[0].value

export default function ScheduleEdit() {
	// const api = useContext(APICtx)
	const { _id } = useParams()
	const queryDate = useQuery().date
	const [ data, setData ] = useState({})
	// const date = data?.date || queryDate

	const setDataDefault = () => setData({
		type: 'collection',
		priority: 'day',
		date: queryDate,
		addressTo: defaultWarehouse,
	})
	useEffect(() => {
		!_id && setDataDefault()
	}, [])

	const title = (_id ? 'Modifica' : 'Crea') + ' pronto per il ritiro'
	const formDef = {
		callSet: [ "transports/set-ready", _id ],
		callGet: _id && "transports/get/"+_id,
		// idRedirect: _id => ([ '/transports/schedule/edit', _id ]),
		data,
		setData: (dataNew) => {
			if(!dataNew || !Object.keys(dataNew).length)
				return setDataDefault()
			
			let tmpFrom = dataNew.addressFrom
			if(data.type==='delivery' && dataNew.type!=='delivery')
				tmpFrom = ''
			if(data.type!=='delivery' && dataNew.type==='delivery')
				tmpFrom = defaultWarehouse

			let tmpTo = dataNew.addressTo
			if(data.type==='collection' && dataNew.type!=='collection')
				tmpTo = ''
			if(data.type!=='collection' && dataNew.type==='collection')
				tmpTo = defaultWarehouse

			setData({
				...dataNew,
				addressFrom: tmpFrom,
				addressTo: tmpTo,
			})
		},
		submitAndClearLabel: !_id && 'Salva e inserisci nuovo',
	}
	return (
		<Tpl title={title} backTo={"/transports/ready"}>
			<Form {...formDef}>
				<Select label="Tipologia" name="type" required options={types} />
				<Input label="Prodotto" name="product" required />
				{ data.type==='delivery' ? (
					<Select label="Indirizzo ritiro" name="addressFrom" required options={warehouses} />
				) : (
					<Input label="Indirizzo ritiro" name="addressFrom" required />
				)}
				{ data.type==='collection' ? (
					<Select label="Indirizzo consegna" name="addressTo" required options={warehouses} />
				) : (
					<Input label="Indirizzo consegna" name="addressTo" required />
				)}
				<Input label="Note" name="notes" multiline />
			</Form>
		</Tpl>
	)
}