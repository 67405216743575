import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Fab from '#Fab'
import Dialog from '#Dialog'
import AddForm from './Add'
import EditForm from './Edit'

import { faEdit, faPlus, faTrash, faMagnifyingGlass, faDownload } from '@fortawesome/pro-regular-svg-icons'

const DialogAdd = ({ dialogAdd, handleCloseDialog }) => {
	return (
		<Dialog
			handleClose={handleCloseDialog}
			open={Boolean(dialogAdd)}
			title="Aggiungi file"
			width="xs"
		>
			{ dialogAdd && <AddForm /> }
		</Dialog>
	)
}

const DialogEdit = ({ dialogEdit, handleCloseDialog }) => {
	return (
		<Dialog
			handleClose={handleCloseDialog}
			open={Boolean(dialogEdit)}
			title={"Modifica informazioni - " + dialogEdit?.filename}
			width="sm"
		>
			{ Boolean(dialogEdit) && <EditForm _id={dialogEdit._id} />}
		</Dialog>
	)
}

const tabDef = [
	{ label:"Nome file", field:'filename' },
]

export default function DocsList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ dialogAdd, setDialogAdd ] = useState(false)
	const [ dialogEdit, setDialogEdit ] = useState(null)

	const aclRW = api.auth.chk('docsrepo-write')

	const handleDelete = ({ _id, filename }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il file "' + filename + '"?',
			onConfirm: () => api.call('docs-repo/del', { _id }).then(handleRefresh),
		})
	}
	const handleAdd = () => setDialogAdd(true)
	const handleEdit = row => setDialogEdit(row)
	const handleCloseDialog = () => {
		setDialogAdd(false)
		setDialogEdit(null)
		return handleRefresh()
	}
	const handleRefresh = () => api.call('docs-repo/list').then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('docs-repo/get', { _id })
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: handleEdit,
		},
		{
			icon: faMagnifyingGlass,
			label: 'Anteprima',
			onClick: row => api.storage.download(row.jwt, true),
			show: row => Boolean(row.jwt),
		},
		{
			icon: faDownload,
			label: 'Scarica',
			onClick: row => api.storage.download(row.jwt, false),
			show: row => Boolean(row.jwt),
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	]

	return (
		<Tpl title='Archivio documenti'>
			<DialogAdd dialogAdd={dialogAdd} handleCloseDialog={handleCloseDialog} />
			<DialogEdit dialogEdit={dialogEdit} handleCloseDialog={handleCloseDialog} />
			<Table def={tabDef} defBtns={defBtns} ids={ids} getRow={getRow} />
			{ aclRW && <Fab label="Aggiungi file" icon={faPlus} onClick={handleAdd} /> }
		</Tpl>
	)
}