import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams } from "react-router-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Box, Typography, Grid, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTruck, faTruckTow, faWarehouseFull } from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import { evalDate } from '#Display'
import { evalAddrFrom, evalAddrTo, evalPriority, evalType } from './const'

const warehouseId = '_warehouse'

const getTransportStyle = (isDragging, draggableStyle) => ({
	background: isDragging ? "lightgreen" : "white",
	...draggableStyle
})
  
const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? "lightblue" : "white",
})

const Transport = ({ transport, index }) => {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})

	useEffect(() => {
		api.call('transports/get/'+transport).then(setData)
	}, [ transport ])

	return (
		<Draggable draggableId={transport} index={index}>
			{(provided, snapshot) => (
				<Paper
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					sx={{ p:2, mt:1, userSelect:'none' }}
					style={getTransportStyle(
						snapshot.isDragging,
						provided.draggableProps.style
					)}
				>
					<Typography>#{index+1} {evalType(data)} - {evalPriority(data)}</Typography>
					<Typography>Prodotto: {data?.product}</Typography>
					{ Boolean(data?.deliveryGroup) && <Typography>Gruppo di consegna: <strong>{data?.deliveryGroup.toUpperCase()}</strong></Typography> }
					<Typography>Da: {evalAddrFrom(data)}</Typography>
					<Typography>A: {evalAddrTo(data)}</Typography>
				</Paper>
			)}
		</Draggable>
	)
}

const List = ({ truckId, transports }) => {
	const api = useContext(APICtx)
	const [ truck, setTruck ] = useState({})

	useEffect(() => {
		truckId && api.call('transports-trucks/get/'+truckId).then(setTruck)
	}, [ truckId ])

	const listIcon = truckId ? (truck.isSmall ? faTruckTow : faTruck) : faWarehouseFull
	return (
		<Droppable droppableId={truckId || warehouseId}>
			{(provided, snapshot) => (
				<Grid item xs={12} lg={6} xl={4} >
					<Paper
						sx={{ p:1 }}
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}
					>
						<Typography variant='h5'>
							<FontAwesomeIcon icon={listIcon} /> {truck.name || 'Non pianificati'}
						</Typography>
						{ transports.map((transport, index) => (
							<Transport key={transport} transport={transport} index={index} />
						))}
						{provided.placeholder}
					</Paper>
				</Grid>
			)}
		</Droppable>
	)
}

export default function Planner() {
	const api = useContext(APICtx)
	const { date } = useParams()
	const [ dateConf, setDateConf ] = useState({})
	const [ plans, setPlans ] = useState({})
	const [ transports, setTransports ] = useState([])

	const handleRefresh = () => date && api.call('transports/date/'+date).then(ret => {
		setDateConf(ret)
		setPlans(ret?.plans || {})
	})
	useEffect(() => {
		handleRefresh()
		date && api.call('transports/list', { date }).then(setTransports)
	}, [ date ])

	const unplanned = useMemo(() => {
		const planned = Object.values(plans).flat(1)
		return transports ? transports.filter(transport => !planned.includes(transport)) : []
	}, [ transports, plans ])

	const onDragEnd = e => {
		if(!e.destination)
			return

		const transport = e.draggableId
		const destTruckId = e.destination.droppableId
		const destIndex = e.destination.index
		const trucksAvailable = Object.values(dateConf?.trucks || []).map(truck => truck._id)
		
		const newPlans = {}
		trucksAvailable.forEach(truckId => {
			const tmp = (plans[truckId] || []).filter(transportCmp => transportCmp!==transport)
			if(truckId===destTruckId)
				tmp.splice(destIndex, 0, transport)
			newPlans[truckId] = tmp
		})
		setPlans(newPlans)
		api.call('transports/setPlans/'+date, { plans:newPlans }).then(handleRefresh)
	}

	const title = 'Pianifica trasporti - ' + evalDate(date)
	return (
		<Tpl title={title} backTo={"/transports/cal/"+date.slice(0, -3)}>
			<DragDropContext onDragEnd={onDragEnd}>
				<Grid container spacing={2}>
					{ Boolean(dateConf?.trucks?.length) && dateConf.trucks.map(truck => (
						<List key={truck._id} truckId={truck._id} transports={plans[truck._id] || []} />
					))}
					<List key={warehouseId} transports={unplanned} />
				</Grid>
			</DragDropContext>
		</Tpl>
	)
}