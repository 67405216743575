import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom"
import { Typography } from '@mui/material'

import { faCheck, faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Cards, { Card } from '#Cards'
import { types } from './const'
import { evalAddrFrom, evalAddrTo } from './const'
import { evalDate, DisplayUser } from '#Display';

const evalToday = () => new Date().toISOString().slice(0, 10)

export default function TrucksTasks() {
	const api = useContext(APICtx)
	const { date=evalToday() } = useParams()
	const [ truck, setTruck ] = useState({})
	const [ tasks, setTasks ] = useState({})

	useEffect(() => {
		api.call('transports-trucks/owned').then(setTruck)
	}, [])
	useEffect(() => {
		date && truck._id && api.call('transports/list', { date, truck:truck._id }).then(setTasks)
	}, [ date, truck._id ])

	const title = 'Trasporti - ' + truck.name + ' - ' + evalDate(date)
	const backTo = api.chkAuthAny([ 'transport-user', 'transport-manager' ]) ? '/transports/cal/'+(date?.slice(0, 7)) : null
	return (
		<Tpl title={title} backTo={backTo}>
			<Cards>
				{ Boolean(tasks.length) && tasks.map(task => (
					<Task key={task} _id={task} />
				)) }
			</Cards>
		</Tpl>
	)
}

const Task = ({ _id }) => {
	const api = useContext(APICtx)
	const [ info, setInfo ] = useState({})

	const typeLabel = types.find(type => type.value === info?.type)?.label
	const addrFrom = info && evalAddrFrom(info)
	const addrTo = info && evalAddrTo(info)
	const planIdx = (info?.truckPlanned?.index) + 1

	const handleRefresh = () => api.call('transports/get/'+_id).then(setInfo)
	useEffect(() => {
		handleRefresh()
	}, [ _id ])

	const actions = [{
		icon: info?.shipDone ? faCircleXmark : faCircleCheck,
		onClick: () => api.call('transports/setFlag/'+_id, { shipDone: !info?.shipDone }).then(handleRefresh),
	}]

	return (
		<Card
			title={<strong>{typeLabel}</strong>}
			avatar={info?.shipDone ? faCheck : planIdx}
			avatarColor={info?.shipDone ? 'success' : 'warning'}
			actions={actions}
		>
			<Typography>
				<strong>Prodotto:</strong> {info?.product}
			</Typography>
			<Typography>
				<strong>Da:</strong> {addrFrom}
			</Typography>
			<Typography>
				<strong>A:</strong> {addrTo}
			</Typography>
			<Typography>
				<strong>Autore:</strong> <DisplayUser uid={info?._editedBy} />
			</Typography>
		</Card>
	)
}
