import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Fab from '#Fab'

import { faEdit, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'

const tabDef = [
	{ label:"Iniziali", field:'shortLabel' },
	{ label:"Denominazione", field:'label' },
	{ label:"Email", field:'email' },
	{ label:"Telefono", field:'phoneNumber' },
	{ label:"Utente collegato", field:'uid', type:'boolean' },
]

export default function AgentsList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])

	const handleDelete = ({ _id, label }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare l\'agente "'+label+'"?',
			onConfirm: () => api.call('agents/del', { _id }).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('agents/list').then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('agents/get', { _id })
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/agents/edit/'+row._id,
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	]

	return (
		<Tpl title='Gestione agenti'>
			<Table def={tabDef} defBtns={defBtns} ids={ids} getRow={getRow} />
			<Fab label="Aggiungi agente" icon={faPlus} linkTo="/agents/edit" />
		</Tpl>
	)
}