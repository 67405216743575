import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'

import { faEdit, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { Typography } from '@mui/material';

const tabDef = [
	{ label:"Descrizione", field:'description' },
	{ label:"Prezzo", field:'price', type:'money' },
]

export default function TransportPricesList() {
	const api = useContext(APICtx)
	const [ socarIds, setSocarIds ] = useState([])
	const [ semitrailerIds, setSemitrailerIds ] = useState([])
	
	const aclRW = api.auth.chk('conf-admin')

	const handleDelete = ({ _id, price }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il prezzo "'+price+'"?',
			onConfirm: () => api.call('transport-prices/del', { _id }).then(handleRefresh),
		})
	}
	const handleRefresh = () => Promise.all([
		api.call('transport-prices/list', { mode:'socar' }).then(setSocarIds),
		api.call('transport-prices/list', { mode:'semitrailer' }).then(setSemitrailerIds),
	])
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('transport-prices/get', { _id })
	const defBtns = !aclRW ? [] : [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/platforms/transport-prices/edit/'+row._id,
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	]

	return (
		<Tpl title='Costi/distanze'>
			<Typography variant="h5">Autocarri Socar</Typography>
			<DataList def={tabDef} rowActions={defBtns} ids={socarIds} getRow={getRow} />
			<Typography variant="h5" sx={{ mt:1 }}>Bilico</Typography>
			<DataList def={tabDef} rowActions={defBtns} ids={semitrailerIds} getRow={getRow} />
			{ aclRW && <Fab label="Aggiungi prezzo" icon={faPlus} linkTo="/platforms/transport-prices/edit" /> }
		</Tpl>
	)
}
