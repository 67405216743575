import React, { useState, useContext, useEffect, useMemo } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'
import Search, { SearchSelect, SearchText } from '#Search'

import { faEdit, faFilePdf, faMagnifyingGlass, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'

const useTableDef = (categories) => useMemo(() => ([
	{ label:"Categoria", content:row => categories.find(cat => cat.value===row.category)?.label || '???' },
	{ label:"Nome", field:'name' },
	{ label:"Altezza lavoro (m)", field:'workingHeight', type:'number' },
	{ label:"Sbraccio (m)", field:'outreach', type:'number' },
	{ label:"Necessario trasporto?", field:'transportRequired', type:'boolean' },
	{ label:"Canone giornaliero", field:'priceRentDaily', type:'money' },
	{ label:"Canone mensile", field:'priceRentMonthly', type:'money' },
	{ label:"Note", field:'notes', type:'multiline' },
]))

const useCsvDef = (categories) => useMemo(() => ([
	{ label:"Categoria", content:row => categories.find(cat => cat.value===row.category)?.label || '???' },
	{ label:"Nome", field:'name' },
	{ label:"Altezza lavoro (m)", field:'workingHeight', type:'number' },
	{ label:"Sbraccio (m)", field:'outreach', type:'number' },
	{ label:"Necessario trasporto?", content:row => row?.transportRequired ? 1 : 0 },
	{ label:"Canone giornaliero", content:row => row.priceRentDaily/10000 },
	{ label:"Canone mensile", content:row => row.priceRentMonthly/10000 },
	{ label:"Note", field:'notes' },
]))

export default function ProdCategoriesList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ searchName, setSearchName ] = useState('')
	const [ searchCategory, setSearchCategory ] = useState('')
	const [ categoriesOpts, setCategoriesOpts ] = useState()
	const tableDef = useTableDef(categoriesOpts)
	const csvDef = useCsvDef(categoriesOpts)
	const csvName = 'schede_tecniche'

	const aclRW = api.auth.chk('conf-datasheets')

	const handleDelete = ({ _id, name }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare la piattaforma "' + name + '"?',
			onConfirm: () => api.call('platforms/del', { _id }).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('platforms/list', { name:searchName, category:searchCategory }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ searchName, searchCategory ])
	useEffect(() => {
		api.call('products-categories/options', { type:'platform' }).then(setCategoriesOpts)
	}, [])

	const getRow = _id => api.call('platforms/get', { _id })
	const defBtns = [
		{
			icon: faMagnifyingGlass,
			label: 'Visualizza PDF',
			onClick: row => api.storage.download(row.datasheetJWT, true),
			show: row => Boolean(row.datasheet),
		},
		{
			icon: faFilePdf,
			label: 'Scarica PDF',
			onClick: row => api.storage.download(row.datasheetJWT, false),
			show: row => Boolean(row.datasheet),
		},
	]
	aclRW && defBtns.push(
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/platforms/edit/'+row._id,
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	)

	const preloadOk = Array.isArray(categoriesOpts)
	return preloadOk && (
		<Tpl title='Gestione schede tecniche'>
			<Search>
				<SearchText label="Cerca per nome" name="name" onChange={setSearchName} />
				<SearchSelect name="category" onChange={setSearchCategory} label="Categoria" options={categoriesOpts} emptyLabel="-- Tutte --" />
			</Search>
			<DataList def={tableDef} csvDef={aclRW && csvDef} csvName={csvName} rowActions={defBtns} ids={ids} getRow={getRow} />
			{ aclRW && <Fab label="Aggiungi una piattaforma" icon={faPlus} linkTo="/platforms/edit" /> }
		</Tpl>
	)
}
