import React, { useState, useContext, useEffect } from 'react';
import { Typography } from '@mui/material';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'
import Search, { SearchSelect, SearchText } from '#Search'
import Dialog from '#Dialog'
import OffersStatusEdit from './OffersStatusEdit'
import offerTypes from './offerTypes.json'
import offerStatus from './offerStatus.json'

import { faEdit, faPlus, faTrash, faMagnifyingGlass, faFilePdf, faCommentCheck } from '@fortawesome/pro-regular-svg-icons'

const EditStatus = ({ offerStatusEdit, handleClose }) => {
	const title = "Modifica stato dell'offerta " + (offerStatusEdit?.fullNumber || '')
	return (
		<Dialog
			handleClose={handleClose}
			open={Boolean(offerStatusEdit?._id)}
			title={title}
			width="md"
		>
			{ Boolean(offerStatusEdit?._id) && <OffersStatusEdit _id={offerStatusEdit?._id} /> }
		</Dialog>
	)
}

const DisplayProduct = ({ _id }) => {
	const api = useContext(APICtx)
	const [ product, setProduct ] = useState({})

	useEffect(() => {
		_id ? api.call('platforms/get', { _id }).then(setProduct) : setProduct({})
	}, [])

	return product?.name || '???'
}

const RentRate = ({ type, product, price }) => {
	const api = useContext(APICtx)
	const [ productData, setProductData ] = useState({})

	useEffect(() => {
		product && api.call('platforms/get', { _id:product }).then(setProductData)
	}, [])

	let rate
	if(type === 'rent-daily')
		rate = price / productData?.priceRentDaily
	if(type === 'rent-monthly')
		rate = price / productData?.priceRentMonthly

	if(!rate)
		return '-'
	const rateColor = rate < 1 ? 'red' : (rate > 1 ? 'green' : undefined)
	const rateSign = rate < 1 ? '-' : (rate > 1 ? '+' : '')
	const rateDelta = Math.round(Math.abs(rate - 1) * 10000) / 100
	return rate ? (
		<Typography color={rateColor}>{rateSign+rateDelta} %</Typography>
	) : '-'
}

const useTableDef = () => {
	const api = useContext(APICtx)

	const def = [
		{ label:"Numero", field:'fullNumber' },
		{ label:"Data", content:row => row._registry.ts, type:'date' },
		{ label:"Tipologia", content:row => offerTypes.find(item => item.value===row.type)?.initials || '???' },
		{ label:"Ragione sociale", field:'offerRecipient' },
		{ label:"Prodotto", content:row => <DisplayProduct _id={row.product} />},
		{ label:"Prezzo", field:'price', type:'money' },
		{ label:"Stato", content:row => (
			<>
				<Typography>{offerStatus.find(item => item.value===row.status?.tag)?.label || '???'}</Typography>
				{ offerStatus.find(item => item.value===row.status?.tag)?.lost && (
					<Typography variant="caption">{row.status?.lostOfferReason}</Typography>
				)}
			</>
		)},
		{ label:"Note", field:'notes', type:'multiline' },
	]
	if(api.auth.chkAny([ 'offers-dataentry', 'offers-manager' ]))
		def.splice(6, 0, { label:'Δ', cellAlign:'center', content:row => <RentRate {...row} /> })

	return def
}

const useCsvDef = () => {
	const api = useContext(APICtx)
	return [
		{ label:"Numero", field:'fullNumber' },
		{ label:"Data", content:row => row._registry.ts, type:'date' },
		{ label:"Tipologia", content:row => offerTypes.find(item => item.value===row.type)?.initials || '???' },
		{ label:"Ragione sociale", field:'offerRecipient' },
		{ label:"Prodotto", content:async row => {
			const data = row?.product && await api.call('platforms/get', { _id:row.product })
			return data?.name || '-'
		}},
		{ label:"Categoria prodotto", content:async row => {
			const data = row?.productCategory && await api.call('products-categories/get', { _id:row.productCategory })
			return data?.label || '-'
		}},
		{ label:"Prezzo (€)", field:'price', type:'number' },
		{ label:"Stato", content:row => offerStatus.find(item => item.value===row.status?.tag)?.label || '???' },
		{ label:"Causa offerta persa", content:row =>
			offerStatus.find(item => item.value===row.status?.tag)?.lost && row.status?.lostOfferReason
		},
		{ label:"Costi trasporto", content:async row => {
			const data = row?.transportCosts && await api.call('transport-prices/get', { _id:row.transportCosts })
			return (data?.description || '-') + (data?.price ? ': '+data.price/10000+'euro' : '')
		}},
		{ label:"Destinazione trasporto", field:'transportDestination' },
		{ label:"Note", field:'notes' },
	]
}

export default function OffersList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ searchString, setSearchString ] = useState('')
	const [ searchType, setSearchType ] = useState('')
	const [ searchTrader, setSearchTrader ] = useState('')
	const [ traders, setTraders ] = useState([])
	const [ offerStatusEdit, setOfferStatusEdit ] = useState({})
	const tableDef = useTableDef()
	const csvDef = useCsvDef()
	const csvName = 'offerte'

	const handlePreviewPdf = ({ _id }) => api.openFile({ tag:'offers/getPdf', params:{ _id } })
	const handleDownloadPdf = ({ _id, _registry }) =>
		api.openFile({
			tag:'offers/getPdf',
			params:{ _id },
			download: 'offerta-' + _registry.number + '.pdf',
		})
	const handleDelete = ({ _id, fullNumber }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare l\'offerta "' + fullNumber + '"?',
			onConfirm: () => api.call('offers/del', { _id }).then(handleRefresh),
		})
	}
	const handleOfferStatusDone = async () => {
		await offerStatusEdit.handleRowRefresh()
		return setOfferStatusEdit(null)
	}
	const handleRefresh = (resetId) =>
		api.call('offers/list', { searchString, searchType, searchTrader }).then(ids => {
			if(resetId) {
				setIds(ids.filter(curId => curId !== resetId))
				setTimeout(() => setIds(ids), 500)
			}
			else
				setIds(ids)
		})
	useEffect(() => {
		handleRefresh()
	}, [ searchString, searchType, searchTrader ])
	useEffect(() => {
		api.auth.chk('offers-dataentry') &&
			api.call('offers/listTraders').then(async tradersIds => {
				setTraders(await Promise.all(
					tradersIds.map(async uid => {
						const user = await api.auth.appUserGet(uid)
						return { value:uid, label:user?.displayName || user?.address || '-' }
					})
				))
			})
	}, [])

	const getRow = _id => api.call('offers/get', { _id })
	const defBtns = [
		{
			icon: faCommentCheck,
			label: 'Imposta stato',
			onClick: setOfferStatusEdit,
			show: row => api.auth.chk('offers-manager') || !offerStatus.find(item => item.value===row.status?.tag)?.offerLocked,
		},
		{ icon:faMagnifyingGlass, label:'Anteprima PDF', onClick:handlePreviewPdf },
		{ icon:faFilePdf, label:'Scarica PDF', onClick:handleDownloadPdf },
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/platforms/offers/edit/'+row._id,
			show: row => !offerStatus.find(item => item.value===row.status?.tag)?.offerLocked,
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete, show:api.auth.chk('offers-manager') },
	]

	return (
		<Tpl title='Offerte'>
			<EditStatus offerStatusEdit={offerStatusEdit} handleClose={handleOfferStatusDone} />
			<Search>
				<SearchText label="Cerca numero / cliente" name="src-string" onChange={setSearchString} />
				<SearchSelect name="src-type" onChange={setSearchType} label="Tipologia" options={offerTypes} emptyLabel="-- Tutti --" />
				{ api.auth.chk('offers-dataentry') && (
					<SearchSelect name="src-trader" onChange={setSearchTrader} label="Commerciale" options={traders} emptyLabel="-- Tutti --" />
				)}
			</Search>
			<DataList
				def={tableDef}
				csvDef={api.auth.chk('offers-manager') && csvDef}
				csvName={csvName}
				rowActions={defBtns}
				ids={ids}
				getRow={getRow}
				pageSize={50}
			/>
			<Fab label="Crea un'offerta" icon={faPlus} linkTo="/platforms/offers/edit" />
		</Tpl>
	)
}
