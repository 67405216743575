import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Fab from '#Fab'

import { faEdit, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'

const tabDef = [
	{ label:"Descrizione", field:'label' },
]

export default function LostOfferReasonList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])

	const handleDelete = ({ _id, label }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare la voce "'+label+'"?',
			onConfirm: () => api.call('lost-offer-reasons/del', { _id }).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('lost-offer-reasons/list').then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('lost-offer-reasons/get', { _id })
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/lost-offer-reasons/edit/'+row._id,
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	]

	return (
		<Tpl title='Gestione cause offerta persa'>
			<Table def={tabDef} defBtns={defBtns} ids={ids} getRow={getRow} />
			<Fab label="Aggiungi una causa" icon={faPlus} linkTo="/lost-offer-reasons/edit" />
		</Tpl>
	)
}
