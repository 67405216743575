import React from 'react';

import Form, { FileUpload } from '#Form'

export default function DocAdd() {
	const formDef = {
		callSet: "docs-repo/add",
	}
	return (
		<Form {...formDef}>
			<FileUpload label="Nuovo file" name="file" tokenEndpoint="docs-repo/get-upload-token" />
		</Form>
	)
}
