import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"

import { useQuery } from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputDate, Select } from '#Form'
import { types, priorities, warehouses } from './const'
import { deliveryGroups } from './deliveryGroups'

const defaultWarehouse = warehouses[0].value

export default function ScheduleEdit() {
	const { _id } = useParams()
	const queryDate = useQuery().date
	const [ data, setData ] = useState({})
	const date = data?.date || queryDate

	const setDataDefault = () => setData({
		type: 'delivery',
		priority: 'day',
		date: queryDate,
		addressFrom: defaultWarehouse,
	})
	useEffect(() => {
		!_id && setDataDefault()
	}, [])

	const title = (_id ? 'Modifica' : 'Crea') + ' trasporto'
	const formDef = {
		callSet: [ "transports/set", _id ],
		callGet: _id && "transports/get/"+_id,
		// idRedirect: _id => ([ '/transports/schedule/edit', _id ]),
		data,
		setData: (dataNew) => {
			if(!dataNew || !Object.keys(dataNew).length)
				return setDataDefault()
			
			let tmpFrom = dataNew.addressFrom
			if(data.type==='delivery' && dataNew.type!=='delivery')
				tmpFrom = ''
			if(data.type!=='delivery' && dataNew.type==='delivery')
				tmpFrom = defaultWarehouse

			let tmpTo = dataNew.addressTo
			if(data.type==='collection' && dataNew.type!=='collection')
				tmpTo = ''
			if(data.type!=='collection' && dataNew.type==='collection')
				tmpTo = defaultWarehouse

			setData({
				...dataNew,
				addressFrom: tmpFrom,
				addressTo: tmpTo,
			})
		},
		submitAndClearLabel: !_id && 'Salva e inserisci nuovo',
	}

	const backTo = date ? "/transports/schedule/"+date : "/transports/ready"
	return (
		<Tpl title={title} backTo={backTo}>
			<Form {...formDef}>
				<InputDate label="Data" name="date" required />
				<Select label="Tipologia" name="type" required options={types} />
				<Select label="Priorità" name="priority" required options={priorities} />
				<Input label="Prodotto" name="product" required />
				{ data.type==='delivery' ? (
					<Select label="Indirizzo ritiro" name="addressFrom" required options={warehouses} />
				) : (
					<Input label="Indirizzo ritiro" name="addressFrom" required />
				)}
				{ data.type==='collection' ? (
					<Select label="Indirizzo consegna" name="addressTo" required options={warehouses} />
				) : (
					<Input label="Indirizzo consegna" name="addressTo" required />
				)}
				<Select label="Gruppo di consegna" name="deliveryGroup" options={deliveryGroups} emptyLabel='--- nessun gruppo ---' />
				<Input label="Note" name="notes" multiline />
			</Form>
		</Tpl>
	)
}