import React from 'react';

import Form, { Input } from '#Form'

export default function DocEdit({ _id }) {
	const formDef = {
		_id,
		callSet: "docs-repo/edit",
		callGet: _id && "docs-repo/get",
	}
	return (
		<Form {...formDef}>
			<Input label="Nome file" name="filename" required />
		</Form>
	)
}
