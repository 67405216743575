import React from 'react';
import { useParams } from "react-router-dom"

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input } from '#Form'

export default function LostOfferReasonsEdit() {
	const { _id } = useParams()

	const title = (_id ? 'Modifica' : 'Crea') + ' causa offerta persa'
	const formDef = {
		_id,
		callSet: "lost-offer-reasons/set",
		callGet: _id && "lost-offer-reasons/get",
		idRedirect: _id => '/lost-offer-reasons/edit/'+_id,
	}
	return (
		<Tpl title={title} backTo="/lost-offer-reasons">
			<Form {...formDef}>
				<Input label="Descrizione" name="label" required />
			</Form>
		</Tpl>
	)
}
