import React from 'react';
import { useParams } from "react-router-dom"

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Select, Checkbox } from '#Form'
import types from './types.json'

export default function ProdCategoriesEdit() {
	const { _id } = useParams()

	const title = (_id ? 'Modifica' : 'Crea') + ' categoria prodotti'
	const formDef = {
		_id,
		callSet: "products-categories/set",
		callGet: _id && "products-categories/get",
		idRedirect: _id => 'products/categories/edit/'+_id,
	}
	return (
		<Tpl title={title} backTo="/products/categories">
			<Form {...formDef}>
				<Select label="Tipologia" name="type" required options={types} emptyLabel="--- seleziona una tipologia ---" />
				<Input label="Nome" name="label" required />
				<Checkbox label="Richiede Kasko" name="requiresKasko" />
			</Form>
		</Tpl>
	)
}
