import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Fab from '#Fab'
import types from './types.json'

import { faEdit, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'

const tabDef = [
	{ label:"Tipologia", content:row => types.find(item => item.value===row.type)?.label || '???' },
	{ label:"Nome", field:'label' },
	{ label:"Richiede Kasko?", field:'requiresKasko', type:'boolean' },
]

export default function ProdCategoriesList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])

	const handleDelete = ({ _id, label }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare la categoria "'+label+'"?',
			onConfirm: () => api.call('products-categories/del', { _id }).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('products-categories/list').then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('products-categories/get', { _id })
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/products/categories/edit/'+row._id,
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	]

	return (
		<Tpl title='Gestione categorie prodotti'>
			<Table def={tabDef} defBtns={defBtns} ids={ids} getRow={getRow} />
			<Fab label="Aggiungi una categoria" icon={faPlus} linkTo="/products/categories/edit" />
		</Tpl>
	)
}
