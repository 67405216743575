import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Select } from '#Form'

export default function AgentsEdit() {
	const api = useContext(APICtx)
	const { _id } = useParams()
	const [ usersOpts, setUsersOpts ] = useState([])

	useEffect(() => {
		api.auth.usersAsOptions().then(setUsersOpts)
	}, [])

	const title = (_id ? 'Modifica' : 'Crea') + ' agente'
	const formDef = {
		_id,
		callSet: "agents/set",
		callGet: _id && "agents/get",
		idRedirect: _id => '/agents/edit/'+_id,
	}
	return (
		<Tpl title={title} backTo="/agents">
			<Form {...formDef}>
				<Input label="Denominazione" name="label" required />
				<Input label="Iniziali" name="shortLabel" required />
				<Input label="Numero di telefono" name="phoneNumber" />
				<Input label="Email" name="email" />
				<Select label="Utente" name="uid" options={usersOpts} emptyLabel="-- Seleziona un utente --" />
			</Form>
		</Tpl>
	)
}
