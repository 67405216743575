import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import TableMui from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Fab from '#Fab'
import Button from '#Button'
import { evalAddrFrom, evalAddrTo, evalPriority, evalType } from './const'
import { deliveryGroups } from './deliveryGroups'
import { evalDate } from '#Display'
import { displayDows } from './const'

import { faEdit, faFileSignature, faPlus, faTrash, faCheck, faTimes, faArrowRotateLeft, faTruck, faTruckTow } from '@fortawesome/pro-regular-svg-icons'
import { faCalendar, faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

const Truck = ({ plan }) => {
	const api = useContext(APICtx)
	const [ truck, setTruck ] = useState({})

	useEffect(() => {
		plan?._id && api.call('transports-trucks/get/'+plan._id).then(setTruck)
	}, [ plan?._id ])

	const icon = truck.isSmall ? faTruckTow : faTruck
	return plan?._id ? (
		<Typography>
			<FontAwesomeIcon icon={icon} /> {truck.letter} #{plan.index+1}
		</Typography>
	) : '-'
}

const tabDef = [
	{ label:"Priorità", content:row => evalPriority(row) },
	{ label:"Tipologia", content:row => evalType(row) },
	{ label:"Prodotto", field:'product' },
	{ label:"Note", field:'notes', type:'multiline' },
	{ label:"Indirizzo", content:row => (
		<>
			<Typography>Da: {evalAddrFrom(row)}</Typography>
			<Typography>A: {evalAddrTo(row)}</Typography>
		</>
	)},
	{ label:"Gruppo", content:row => deliveryGroups.find(scan => scan.value === row.deliveryGroup)?.icon || null, type:'icon' },
	{ label:"Autocarro", content:row => <Truck plan={row.truckPlanned} />},
	{ label:"DDT", type:'icon', content:row => (row.ddtDone ? (row.ddtValid ? faCheck : faArrowRotateLeft) : faTimes)},
	{ label:"Consegna", type:'bool', field:'shipDone' },
]

const DateSelector = ({ date }) => {
	const ts = +new Date(date)
	const todayDateObj = new Date()
	const prevDateObj = new Date(ts)
	do {
		prevDateObj.setDate(prevDateObj.getDate() - 1)
	} while (!displayDows.includes(prevDateObj.getDay()))
	const nextDateObj = new Date(ts)
	do {
		nextDateObj.setDate(nextDateObj.getDate() + 1)
	} while (!displayDows.includes(nextDateObj.getDay()))

	const displayDate = date.split('-').reverse().join('/')
	const todayDate = todayDateObj.getFullYear() + '-' +
		(todayDateObj.getMonth() + 1).toString().padStart(2, '0') + '-' +
		todayDateObj.getDate().toString().padStart(2, '0')
	const prevDate = prevDateObj.getFullYear() + '-' +
		(prevDateObj.getMonth() + 1).toString().padStart(2, '0') + '-' +
		prevDateObj.getDate().toString().padStart(2, '0')
	const nextDate = nextDateObj.getFullYear() + '-' +
		(nextDateObj.getMonth() + 1).toString().padStart(2, '0') + '-' +
		nextDateObj.getDate().toString().padStart(2, '0')

	return (
		<TableContainer component={Paper}>
			<TableMui sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>
							<Box sx={{ display:'flex', justifyContent:'space-between' }}>
								<Box sx={{ mt:'auto', mb:'auto' }}>
									<Button icon={faChevronLeft} linkTo={"/transports/schedule/" + prevDate} />
								</Box>
								<Box>
									<Typography variant='h4'>
										<Button icon={faCalendar} linkTo={"/transports/schedule/" + todayDate} />
										{displayDate}
									</Typography>
								</Box>
								<Box sx={{ mt:'auto', mb:'auto' }}>
									<Button icon={faChevronRight} linkTo={"/transports/schedule/" + nextDate} />
								</Box>
							</Box>
						</TableCell>
					</TableRow>
				</TableHead>
			</TableMui>
		</TableContainer>
	)
}

export default function ScheduleList() {
	const api = useContext(APICtx)
	const { date } = useParams()
	const [ ids, setIds ] = useState([])
	const [ dateConf, setDateConf ] = useState({})

	const rw = api.auth.chkAll(dateConf.locked ? [ 'transport-manager', 'transport-user' ] : [ 'transport-user' ])

	const handleDDT = ({ _id, ddtDone }) =>
		api.call('transports/setFlag/'+_id, { ddtDone:!ddtDone, ddtValid:true }).then(handleRefresh)

	const handleDelete = ({ _id, product }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il trasporto del prodotto "'+product+'"?',
			onConfirm: () => api.call('transports/del/'+_id).then(handleRefresh),
		})
	}

	const handleRefresh = () => date && api.call('transports/list', { date }).then(setIds)
	useEffect(() => {
		handleRefresh()
		date && api.call('transports/date/'+date).then(setDateConf)
	}, [ date ])

	const getRow = _id => api.call('transports/get/'+_id)
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/transports/schedule/edit/'+row._id,
			show: row => !row.truckPlanned,
		},
		{
			icon: faFileSignature,
			label: 'Imposta DDT',
			onClick: handleDDT,
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
			show: row => !row.truckPlanned,
		},
	]

	const title = 'Trasporti schedulati - ' + evalDate(date) + (dateConf.locked ? ' - Data bloccata' : '')
	return (
		<Tpl title={title} backTo={"/transports/cal/"+date.slice(0, -3)}>
			<DateSelector date={date} />
			<Table def={tabDef} defBtns={rw && defBtns} ids={ids} getRow={getRow} />
			{ rw && <Fab label="Aggiungi trasporto" icon={faPlus} linkTo={"/transports/schedule/edit?date="+date} /> }
		</Tpl>
	)
}
