import React, { useState, useContext, useEffect } from 'react';
// import { Box, Typography } from '@mui/material';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Fab from '#Fab'
import { evalAddrFrom, evalAddrTo, evalType } from './const'
import DataList from '#DataList'

import { faEdit, faCalendarPen, faPlus, faTrash, faTruckRampBox } from '@fortawesome/pro-regular-svg-icons'

const tabDef = [
	{ label:"Tipologia", content:row => evalType(row) },
	{ label:"Prodotto", field:'product' },
	{ label:"Da", content:evalAddrFrom },
	{ label:"A", content:evalAddrTo },
	// { label:"Indirizzo", content:row => (
	// 	<>
	// 		<Typography>Da: {evalAddrFrom(row)}</Typography>
	// 		<Typography>A: {evalAddrTo(row)}</Typography>
	// 	</>
	// )},
	{ label:"Note", field:'notes', type:'multiline' },
	{ label:"Autore", field:'_editedBy', type:'user'},
]

export default function ScheduleList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])

	const rw = api.auth.chkAll([ 'transport-manager', 'transport-user' ])

	const handleDelete = ({ _id, product }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il trasporto del prodotto "'+product+'"?',
			onConfirm: () => api.call('transports/del/'+_id).then(handleRefresh),
		})
	}
	const handleTake = ({ _id, product }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler prendere in carico sulla lista odierna il trasporto del prodotto "'+product+'"?',
			onConfirm: () => api.call('transports/take/'+_id).then(handleRefresh),
		})
	}

	const handleRefresh = () => api.call('transports/list', { date:null }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [])
	
	const getRow = _id => api.call('transports/get/'+_id)
	const defBtns = []
	if(api.auth.chkAny([ 'transport-user', 'transport-manager' ]))
		defBtns.push(
			{ icon:faEdit, label:'Modifica', linkTo: row => '/transports/ready/edit/'+row._id },
			{ icon:faCalendarPen, label:'Assegna un giorno', linkTo: row => '/transports/schedule/edit/'+row._id },
		)
	if(api.auth.chk('transport-driver'))
		defBtns.push(
			{ icon:faTruckRampBox, label:'Prendi in carico', onClick:handleTake },
		)
	if(api.auth.chkAny([ 'transport-user', 'transport-manager' ]))
		defBtns.push(
			{ icon:faTrash, label:'Elimina', onClick:handleDelete },
		)

	return (
		<Tpl title="Pronti per il ritiro">
			{/* <Table def={tabDef} defBtns={rw && defBtns} ids={ids} getRow={getRow} /> */}
			<DataList def={tabDef} rowActions={defBtns} ids={ids} getRow={getRow} />
			{/* <Cards>
				{ ids.map(_id => (
					<Task key={_id} _id={_id} />
				)) }
			</Cards> */}
			{ rw && <Fab label="Aggiungi trasporto" icon={faPlus} linkTo={"/transports/ready/edit"} /> }
		</Tpl>
	)
}

// const Task = ({ _id }) => {
// 	const api = useContext(APICtx)
// 	const [ info, setInfo ] = useState({})

// 	const addrFrom = evalAddrFrom(info)
// 	const addrTo = evalAddrTo(info)

// 	const handleRefresh = () => api.call('transports/get/'+_id).then(setInfo)
// 	useEffect(() => {
// 		handleRefresh()
// 	}, [ _id ])

// 	const handleDelete = () => {
// 		api.dialogConfirm({
// 			text: 'Sei sicuro di voler cancellare il trasporto del prodotto "'+info.product+'"?',
// 			onConfirm: () => api.call('transports/del/'+_id).then(handleRefresh),
// 		})
// 	}
// 	const handleTake = () => {
// 		api.dialogConfirm({
// 			text: 'Sei sicuro di voler prendere in carico sulla lista odierna il trasporto del prodotto "'+info.product+'"?',
// 			onConfirm: () => api.call('transports/take/'+_id).then(handleRefresh),
// 		})
// 	}

// 	const actions = []
// 	if(api.auth.chkAny([ 'transport-user', 'transport-manager' ]))
// 		actions.push(
// 			{ icon:faEdit, label:'Modifica', linkTo: '/transports/ready/edit/'+info._id },
// 			{ icon:faCalendarPen, label:'Assegna un giorno', linkTo: '/transports/schedule/edit/'+info._id },
// 		)
// 	if(api.auth.chk('transport-driver'))
// 		actions.push(
// 			{ icon:faTruckRampBox, label:'Prendi in carico', onClick:handleTake },
// 		)
// 	actions.push(
// 		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
// 	)

// 	return (
// 		<Card
// 			title={<strong>{evalType(info)}</strong>}
// 			actions={actions}
// 		>
// 			<Typography>
// 				<strong>Prodotto:</strong> {info.product}
// 			</Typography>
// 			<Typography>
// 				<strong>Da:</strong> {addrFrom}
// 			</Typography>
// 			<Typography>
// 				<strong>A:</strong> {addrTo}
// 			</Typography>
// 			{ info?.notes && (
// 				<>
// 					<Typography>Note:</Typography>
// 					<Typography variant="caption">
// 					{ info.notes.split("\n").map((line, lineIdx) =>
// 						<Box key={lineIdx} sx={{ mx:1 }}>{line}</Box>
// 					)}
// 					</Typography>
// 				</>
// 			)}
// 		</Card>
// 	)
// }
