import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputDate, InputNumber, Select } from '#Form'
import typesDef from './types.json'

export default function TrucksEdit() {
	const api = useContext(APICtx)
	const { assetType, _id } = useParams()
	const [ agentsOpts, setAgentsOpts ] = useState([])

	const assetDef = typesDef.find(item => item.value===assetType)
	
	const isNew = !_id
	const fullEditable = !_id || api.auth.chk('assets-timemachine')

	const title = (_id ? 'Modifica' : 'Crea') + ' ' + assetDef?.labelOne
	const formDef = {
		callSet: isNew ? 'assets/create' : (fullEditable ? "assets/admin-update" : "assets/update"),
		callGet: _id && "assets/get/"+_id,
		extraData: { type:assetType },
		_id,
		submitAndClearLabel: !_id && 'Salva e inserisci nuovo',
	}
	useEffect(() => {
		api.call('agents/options').then(setAgentsOpts)
	}, [])

	return (
		<Tpl title={title} backTo={"/assets/"+assetType}>
			<Form {...formDef}>
				{ !isNew && <Input label="Bene" name="number" required disabled /> }
				{ assetDef?.fields.includes('shortDescription') && <Input label="Descrizione ridotta" name="shortDescription" required /> }
				{ assetDef?.fields.includes('equipments') && <Input label="Attrezzatura" name="equipments" multiline required disabled={!fullEditable} /> }
				{ assetDef?.fields.includes('supplier') && <Input label="Fornitore" name="supplier" required /> }
				{ assetDef?.fields.includes('date') && <InputDate label="Data" name="date" required disabled={!fullEditable} /> }
				{ assetDef?.fields.includes('previousCustomer') && <Input label="Ex cliente" name="previousCustomer" required /> }
				{ assetDef?.fields.includes('customer') && <Input label="Cliente" name="customer" required /> }
				{ assetDef?.fields.includes('agent') && <Select label="Agente" name="agent" options={agentsOpts} required emptyLabel="-- seleziona un agente --" /> }
				{ assetDef?.fields.includes('price') && <InputNumber label="Prezzo" name="price" required suffix="€" /> }
				{ assetDef?.fields.includes('rebate') && <InputNumber label="Sconto" name="rebate" required suffix="%" disabled={!fullEditable} /> }
				{ assetDef?.fields.includes('rebateBattery') && <InputNumber label="Sconto batteria" name="rebateBattery" required suffix="%" disabled={!fullEditable} /> }
				{ assetDef?.fields.includes('protocol') && <Input label="Protocollo" name="protocol" required /> }
				{ assetDef?.fields.includes('model') && <Input label="Modello" name="model" required disabled={!fullEditable} /> }
				{ assetDef?.fields.includes('serial') && <Input label="Matricola" name="serial" /> }
				{ assetDef?.fields.includes('assetRef') && <Input label="Rif bene" name="assetRef" /> }
				{ assetDef?.fields.includes('ddtNumber') && <Input label="Numero DDT" name="ddtNumber" /> }
				{ assetDef?.fields.includes('ddtDate') && <InputDate label="Data DDT" name="ddtDate" /> }
				{ assetDef?.fields.includes('sellPrice') && <InputNumber label="Importo vendita" name="sellPrice" suffix="€" /> }
				{ assetDef?.fields.includes('finalCustomer') && <Input label="Cliente finale" name="finalCustomer" /> }
				<Input label="Note" name="notes" multiline />
			</Form>
		</Tpl>
	)
}