import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Fab from '#Fab'

import { faEdit, faPlus } from '@fortawesome/pro-regular-svg-icons'

const tabDef = [
	{ label:"Sigla", field:'letter' },
	{ label:"Nome", field:'name' },
	{ label:"Utente assegnato", type:'user', field:'user' },
	{ label:"Dimensioni", content:row => row.isSmall ? 'ridotte' : 'standard' },
	{ label:"Dal", type:'date', field:'begin' },
	{ label:"Al", type:'date', field:'end' },
]

export default function TrucksList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])

	const handleRefresh = () => api.call('transports-trucks/list').then(setIds)
	useEffect(() => { handleRefresh() }, [])

	const getRow = _id => api.call('transports-trucks/get/'+_id)
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/transports/trucks/edit/'+row._id,
		},
	]

	return (
		<Tpl title='Gestione autocarri'>
			<Table def={tabDef} defBtns={defBtns} ids={ids} getRow={getRow} />
			<Fab label="Aggiungi autocarro" icon={faPlus} linkTo="/transports/trucks/edit" />
		</Tpl>
	)
}