import React from 'react';
import { useParams } from "react-router-dom"

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Select, InputNumber } from '#Form'
import transportTypes from './transportTypes.json'

export default function TransportPricesEdit() {
	const { _id } = useParams()

	const title = (_id ? 'Modifica' : 'Crea') + ' prezzo'
	const formDef = {
		_id,
		callSet: "transport-prices/set",
		callGet: _id && "transport-prices/get",
		idRedirect: _id => '/platforms/transport-prices/edit/'+_id,
	}
	return (
		<Tpl title={title} backTo="/platforms/transport-prices">
			<Form {...formDef}>
				<Select label="Tipologia" name="mode" required options={transportTypes} emptyLabel="--- seleziona un tipo di trasporto ---" />
				<Input label="Dettagli extra/città" name="details" />
				<InputNumber label="Dal Km" name="kmFrom" suffix="Km" />
				<InputNumber label="Al Km" name="kmTo" suffix="Km" />
				<InputNumber label="Prezzo" name="price" required suffix="€" />
			</Form>
		</Tpl>
	)
}
